<template>
    <div v-if="galleryContent.length >= 1" id="exhibition-opening">
      <swiper :id="swiperId" class="swiper" :options="swiperOptions2">
          <swiper-slide v-for="image in galleryContent" :key="image.name" class="swiper-slide">
              <img v-if="image.formats.thumbnail.width >= image.formats.thumbnail.height" class="image-landscape swiper-lazy" :src="api_url + image.formats.medium.url" :alt="'nsfs-' + image.caption" :width="image.width" :height="image.height">
              <img v-else-if="image.formats.thumbnail.width <= image.formats.thumbnail.height" class="image-portrait swiper-lazy" :src="api_url + image.formats.medium.url" :alt="'nsfs-' + image.caption" :width="image.width" :height="image.height">
              <div class="swiper-lazy-preloader" />
              <figcaption v-if="image.caption">
                  {{ image.caption }}
              </figcaption>
          </swiper-slide>

          <div v-show="galleryContent.length > 1" slot="button-prev" class="swiper-button-prev-unique gallery--left-side" />
          <div v-show="galleryContent.length > 1" slot="button-next" class="swiper-button-next-unique gallery--right-side" />
      </swiper>

      <div v-if="galleryContent.length > 1" :slot="'pagination-' + swiperId" class="swiper-pagination swiper-target-2 metatext"></div>

      <div v-if="galleryContent.length > 1" slot="pagination" class="swiper-pagination metatext" />
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
    components: {
        Swiper,
        SwiperSlide
    },
    props: {
        galleryContent: {
            type: Array,
            default: () => ([])
        }
    },

    data: function() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            swiperOptions2: {
            // autoHeight: true,
            slidesPerView: 1,
            spaceBetween: 25,
            resistanceRatio: 0,
            preloadImages: true,
            loop: true,
            lazy: {
              loadPrevNext: true,
              loadPrevNextAmount: 1
            },
            navigation: {
              nextEl: '.swiper-button-next-unique',
              prevEl: '.swiper-button-prev-unique'
            },
            pagination: {
              el: '.swiper-target-2',
              type: 'fraction'
            },
        renderFraction: function(currentClass, totalClass) {
          return '<span class="' + currentClass + '"></span>' +
            ' of ' +
            '<span class="' + totalClass + '"></span>';
        }
  },
        }
    },
    computed: {
    swiperId() {
      // Generate a unique identifier for this instance of the Swiper component
      return 'swiper-' + Math.floor(Math.random() * 100000);
    },
    
  }
}
</script>