<template>
  <div
    id="to-top"
    class="to-top-btn to-top-btn--hidden"
    @click.self="scrollToTop"
  >
    <i class="arrow up" />
  </div>
</template>

<script>
export default {
    mounted() {
        window.addEventListener('scroll', function() {
            let scrollBtn = document.getElementById('to-top');
            if (window.scrollY > 2000) {
                scrollBtn.classList.remove('to-top-btn--hidden');
                scrollBtn.classList.add('to-top-btn--visible');
            } else {
                scrollBtn.classList.add('to-top-btn--hidden');
                scrollBtn.classList.remove('to-top-btn--visible');
            }
        })
    },
    methods: {
        scrollToTop() {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }
}
</script>

<style lang="scss" scoped>
    .to-top-btn {
        --accentColor: #008B59;
        --lightGreen: #E8F5F0;
        transition: all .15s ease;
        z-index: 13399;
        position: fixed;
        bottom: 1.75rem;
        right: 1.75rem;
        padding: 1rem 1.25rem;
        height: 50px;
        width: 50px;
        background: var(--lightGreen);
        color: white;
        font-size: 1.25rem;
        cursor: pointer;
        border: 1px solid var(--lightGreen);
        &:hover {
            background: var(--accentColor);
            border: 1px solid var(--accentColor);
            .arrow {
                border-color: var(--lightGreen);
            }
        }
        .arrow {
            border-color: var(--accentColor);
            margin-bottom: -1px;
        }
        @media only screen and( max-width: 1023px) {
            display: none;
        }
    }
    .to-top-btn--visible {
          transform: translateY( 0px );
    }
    .to-top-btn--hidden {
        transform: translateY( 100px );
    }
</style>
