<template>
    <section id="single-exhibition">
        <BaseNotificationMessage v-if="exhibition[0] && checkEN && $i18n.locale === 'en'"
            :static-message-e-n="staticMessageEN" :message-type="messageType" />

        <ToTop />



        <div v-if="exhibition[0]" class="defaultcontent-grid">
            <PhotoCover :photo-cover="exhibition[0].photo_cover" />

            <div id="exhibition-header">
                <h1 class="title-header">
                    <span v-if="exhibition[0].title_main_artist">{{ exhibition[0].title_main_artist }}:<br></span>
                    <span>{{ exhibition[0].title }}<br></span>
                    <span v-if="exhibition[0].subtitle">{{ exhibition[0].subtitle }}</span>
                </h1>
                <div class="date-header">
                    <span>{{ exhibition[0].date_start | formatDateFull }}</span>
                    <span>{{ exhibition[0].date_end | formatDateFull }}</span>
                </div>
            </div>

            <div class="singlepage-grid-primary">
                <div v-if="exhibition[0].content_no" id="exhibition-description">
                    <MarkedParser v-if="$i18n.locale === 'nb'" class="markdown"
                        :marked-content="exhibition[0].content_no" />
                    <MarkedParser v-else-if="exhibition[0].content_en === '' || exhibition[0].content_en === null"
                        class="markdown" :marked-content="exhibition[0].content_no" />
                    <MarkedParser v-else class="markdown" :marked-content="exhibition[0].content_en" />
                </div>
            </div>

            <div class="singlepage-grid-secondary metatext">
                <div v-if="exhibition[0].photo_gallery.length > 1 || exhibition[0].rel_int.length > 1 || exhibition[0].presslist.length > 1 || exhibition[0].photo_opening.length > 1"
                    class="exh-btn-wrapper">
                    <button v-if="exhibition[0].photo_gallery.length > 1" id="scrollto-btn"
                        data-scrolltarget="exhibition-gallery" class="exh-btn ticket-btn-green" @click.self="scrollTo">
                        <span class="metatext">
                            {{ $t('buttons.viewGallery') }}
                        </span>
                    </button>

                    <button v-if="exhibition[0].photo_opening.length > 1" id="scrollto-btn"
                        data-scrolltarget="exhibition-opening" class="exh-btn ticket-btn-green" @click.self="scrollTo">
                        <span class="metatext">
                            {{ $t('buttons.viewOpening') }}
                        </span>
                    </button>

                    <button v-if="exhibition[0].rel_int.length > 1" id="scrollto-btn"
                        data-scrolltarget="exhibition-online" class="exh-btn ticket-btn-green" @click.self="scrollTo">
                        <span class="metatext">
                            {{ $t('buttons.viewOnline') }}
                        </span>
                    </button>

                    <button v-if="exhibition[0].presslist.length > 1" id="scrollto-btn"
                        data-scrolltarget="exhibition-reviews" class="exh-btn ticket-btn-green" @click.self="scrollTo">
                        <span class="metatext">
                            {{ $t('buttons.viewReviews') }}
                        </span>
                    </button>

                    <button v-if="exhibition[0].ticket_url" id="purchaseticket-btn" class="exh-btn ticket-btn-green">
                        <a :href="exhibition[0].ticket_url | cleanUrl" target="_blank" rel="noreferrer noopener">
                            <span>{{ $t('buttons.purchaseTicket') }}</span>
                            <svgExternal />
                        </a>
                    </button>
                </div>

                <ul v-if="exhibition[0].artists.length > 0" id="exhibition-sublist">
                    <p class="metatext-header">
                        {{ $tc('exhibition.artists', exhibition[0].artists.length) }}
                    </p>
                    <li v-for="artist in exhibition[0].artists" :key="artist.last_name">
                        <span v-if="artist.extra_artist_category == null"
                            @click.self="pushToPanel(artist.slug, 'getArtist')">{{ artist.first_name }} {{ artist.last_name }}</span>
                    </li>
                </ul>

                <ul v-if="artistsDance.length > 0" id="exhibition-sublist">
                    <p v-for="artist in artistsDance.slice(0,1)" :key="artist.id">
                        <span class="metatext-header">{{ $t('exhibition.dance') }}</span>
                    </p>
                    <li v-for="artist in artistsDance" :key="artist.last_name">
                        <span v-if="artist.extra_artist_category == 'Dance'"
                            @click.self="pushToPanel(artist.slug, 'getArtist')">{{ artist.first_name }} {{ artist.last_name }}</span>
                    </li>
                </ul>

                <ul v-if="artistsPerformance.length > 0" id="exhibition-sublist">
                    <p v-for="artist in artistsPerformance.slice(0,1)" :key="artist.id">
                        <span v-if="artist.extra_artist_category == 'Performance'" class="metatext-header">{{ $t('exhibition.performance') }}</span>
                    </p>
                    <li v-for="artist in artistsPerformance" :key="artist.last_name">
                        <span v-if="artist.extra_artist_category == 'Performance'"
                            @click.self="pushToPanel(artist.slug, 'getArtist')">{{ artist.first_name }} {{ artist.last_name }}</span>
                    </li>
                </ul>

                <ul v-if="artistsPerformingArts.length > 0" id="exhibition-sublist">
                    <p v-for="artist in artistsPerformingArts.slice(0,1)" :key="artist.id">
                        <span v-if="artist.extra_artist_category == 'PerformingArts'" class="metatext-header">{{ $t('exhibition.performingArts') }}</span>
                    </p>
                    <li v-for="artist in artistsPerformingArts" :key="artist.last_name">
                        <span v-if="artist.extra_artist_category == 'PerformingArts'"
                            @click.self="pushToPanel(artist.slug, 'getArtist')">{{ artist.first_name }} {{ artist.last_name }}</span>
                    </li>
                </ul>

                <ul v-if="exhibition[0].rel_cur.length > 0" id="exhibition-sublist">
                    <p class="metatext-header">
                        {{ $t('exhibition.curators') }}
                    </p>
                    <li v-for="curator in exhibition[0].rel_cur" :key="curator.last_name">
                        <span @click.self="pushToPanel(curator.slug, 'getCurator')">{{ curator.first_name }}
                            {{ curator.last_name }}</span>
                    </li>
                </ul>

                <ul v-if="exhibition[0].thank_list.length > 0" id="exhibition-sublist">
                    <p class="metatext-header">
                        {{ $t('exhibition.credits') }}
                    </p>
                    <li v-for="credit in exhibition[0].thank_list" :key="credit.name">
                        <span v-if="credit.url">
                            <a :href="credit.url | cleanUrl" target="_blank" rel="noreferrer noopener">
                                {{ credit.name }}
                            </a>
                        </span>
                        <span v-else class="no-link">{{ credit.name }}</span>
                    </li>
                </ul>

                <ul v-if="exhibition[0].rel_pub.length > 0" id="exhibition-sublist">
                    <p class="metatext-header">
                        {{ $tc('exhibition.publications', exhibition[0].rel_pub.length ) }}
                    </p>
                    <li v-for="pub in exhibition[0].rel_pub" :key="pub.title">
                        <span @click.self="pushToPanel(pub.slug, 'getPublication')">{{ pub.title }}</span>
                    </li>
                </ul>

                <!-- <ul v-if="exhibition[0].rel_pst.length > 0" id="exhibition-sublist">
                    <p class="metatext-header">{{ $t('exhibition.news')}}</p>
                    <li v-for="pst in exhibition[0].rel_pst" :key="pst.title" class="exhibition-sublist--item">
                        <router-link :to="{ path: '/news/' + pst.slug }">
                            <span v-if="$i18n.locale === 'nb'">{{ pst.title_no }}</span>
                            <span v-else>{{ pst.title_en }}</span>
                        </router-link>
                    </li>
                </ul> -->

                <ul v-if="exhibition[0].downloadables.length > 0" id="exhibition-downloadables" class="downloadlist">
                    <p class="metatext-header">
                        {{ $tc('exhibition.downloadables', exhibition[0].downloadables.length) }}
                    </p>
                    <li v-for="file in exhibition[0].downloadables" :key="file.name">
                        <a :href="api_url + file.url" target="_blank" rel="noreferrer noopener" download
                            class="download-link">{{ file.caption }} ({{ file.ext }})
                            <span>
                                <svgDownload />
                            </span>
                        </a>
                    </li>
                </ul>

                <ul v-if="exhibition[0].logos.length > 0" id="exhibition-logos" class="logos">
                    <li v-for="file in exhibition[0].logos" :key="file.name" class="logo">
                        <a v-if="file.caption" :href="file.caption | cleanUrl" target="_blank"
                            rel="noreferrer noopener"><img :src="api_url + file.url" :alt="file.name"></a>
                        <img v-else :src="api_url + file.url" :alt="file.name">
                    </li>
                </ul>

                <div v-if="exhibition[0].digital_poster" class="exhibition-digitalposter">
                    <img :src="api_url + exhibition[0].digital_poster.formats.small.url"
                        :alt="exhibition[0].title + ' digital poster'">
                </div>
            </div>

            <ExhibitionGallery v-if="exhibition[0].photo_gallery.length > 0" :gallery-content="exhibition[0].photo_gallery" />

            <ExhibitionOpening v-if="exhibition[0].photo_opening.length > 0" :gallery-content="exhibition[0].photo_opening" />

            <div v-if="exhibition[0].rel_int.length > 0" id="exhibition-online"
                class="defaultcontent-grid--fullwidth exhibition-list">
                <h1 class="section-header">
                    {{ $t('exhibition.interviews') }}
                </h1>
                <div id="collection">
                    <ListComponent :list-content="exhibition[0].rel_int" />
                </div>
            </div>

            <div v-if="exhibition[0].presslist.length > 0" id="exhibition-reviews"
                class="defaultcontent-grid--fullwidth exhibition-list">
                <h1 class="section-header">
                    {{ $t('exhibition.press') }}
                </h1>
                <div id="collection">
                    <ListComponent :list-content="exhibition[0].presslist" />
                </div>
            </div>
        </div>

        <SlidePanel ref="panel" />
    </section>
</template>

<script>
    import PhotoCover from '@/components/PhotoCover'
    import ListComponent from '@/components/ListComponent'
    import SlidePanel from '@/components/SlidePanel'
    import BaseNotificationMessage from '@/components/base/BaseNotificationMessage'
    import ExhibitionGallery from '@/components/exhibitions/ExhibitionGallery'
    import ExhibitionOpening from '@/components/exhibitions/ExhibitionOpening'
    import MarkedParser from '@/components/MarkedParser'
    import ToTop from '@/components/base/ToTop'
    import svgDownload from '@/components/base/svgDownload'
    import svgExternal from '@/components/base/svgExternal'

    import gql from 'graphql-tag'

    const getExhibition = gql `
  query Exhibition($slug: String!) {
    exhibitions(limit: 1, where: { slug: $slug }) {
        id
        slug
        title
        subtitle
        title_main_artist
        date_start
        date_end
        content_en
        content_no
        contentShort_no
        ticket_url  
        photo_gallery {
            formats
            caption
            height
            width
        }
        photo_cover {
            formats
            caption
            height
            width
        }
        photo_opening {
            formats
            caption
        }
        logos {
            caption
            formats
            url
            name
        }
        digital_poster {
            formats
        }
        presslist {
            published_date
            title_en
            title_no
            author
            url
            file {
                url
            }
        }
        thank_list {
            name
            url
        }
        downloadables {
            name
            url
            ext
            caption
        }
        artists {
            first_name
            last_name
            slug
            country_en
            country_no
            website
            extra_artist_category
        }
        rel_pub {
            slug
            title
        }
        rel_cur {
            slug
            first_name
            last_name
        }
        rel_pst {
            slug
            title_en
            title_no
        }
        rel_int {
            slug
            title_en
            title_no
            manual_post_date
        }
        }
    }
`;

    export default {
        apollo: {
            exhibition: {
                query: getExhibition,
                variables() {
                    return {
                        slug: this.$route.params.slug,
                    };
                },
                update: data => data.exhibitions
            }
        },
        components: {
            PhotoCover,
            ListComponent,
            SlidePanel,
            BaseNotificationMessage,
            ExhibitionGallery,
            ExhibitionOpening,
            MarkedParser,
            ToTop,
            svgDownload,
            svgExternal
        },
        data() {
            return {
                ogTitle: '',
                ogDesc: '',
                ogImage: '',
                fullogTitle: '',
                staticMessageNO: 'Denne siden er ikke tilgjengelig på engelsk',
                staticMessageEN: 'This page is not available in English',
                messageType: 'error',
                exhibition: {},
                api_url: process.env.VUE_APP_STRAPI_API_URL,
                slug: this.slug,
                slider: null,
            };
        },
        metaInfo() {
            return {
                title: this.fullogTitle,
                meta: [{
                        property: 'og:title',
                        content: this.fullogTitle + ' | Nitja senter for samtidskunst',
                        vmid: 'og:title'
                    },
                    {
                        property: 'og:description',
                        content: this.ogDesc,
                        vmid: 'og:description'
                    },
                    {
                        property: 'og:image',
                        content: this.api_url + this.ogImage,
                        vmid: 'og:image'
                    },
                    {
                        property: 'og:type',
                        content: 'article',
                        vmid: 'og:type'
                    },
                    {
                        property: 'article:publisher',
                        content: 'https://www.nitja.no/',
                        vmid: 'article:publisher'
                    },
                    {
                        property: 'twitter:card',
                        content: this.api_url + this.ogImage,
                        vmid: 'twitter:card'
                    },
                    {
                        property: 'twitter:title',
                        content: this.fullogTitle + ' | Nitja senter for samtidskunst',
                        vmid: 'twitter:title'
                    },
                    {
                        property: 'twitter:description',
                        content: this.ogDesc,
                        vmid: 'twitter:description'
                    },
                ]
            }
        },
        computed: {
            checkEN: function () {
                let exhibition = this.exhibition[0];
                let textEN = exhibition.content_en
                if (textEN === '' || textEN === null) {
                    return true
                }
                return false
            },
              artistsDance: function () {
                    return this.exhibition[0].artists.filter(i => i.extra_artist_category === 'Dance')
                },
                artistsPerformance: function () {
                    return this.exhibition[0].artists.filter(i => i.extra_artist_category === 'Performance')
                },
                artistsPerformingArts: function () {
                    return this.exhibition[0].artists.filter(i => i.extra_artist_category === 'PerformingArts')
                }

        },
        updated() {
            let exhibitionTitle = this.exhibition[0].title;
            let exhibitionSubtitle = this.exhibition[0].subtitle;
            let exhibitionMainArtist = this.exhibition[0].title_main_artist;
            let exhibitionDescription = this.exhibition[0].contentShort_no;
            let exhibitionCover = this.exhibition[0].photo_cover.formats.medium.url;
            if (exhibitionTitle) {
                this.ogTitle = exhibitionTitle
            }
            if (exhibitionDescription) {
                this.ogDesc = exhibitionDescription
            }
            if (exhibitionCover) {
                this.ogImage = exhibitionCover
            }
            if (exhibitionSubtitle) {
                this.ogSubtitle = exhibitionSubtitle
            }
            if (exhibitionMainArtist) {
                this.ogMainArtist = exhibitionMainArtist
            }

            // this.fullogTitle = exhibitionMainArtist + ' ' + exhibitionTitle + ' ' + exhibitionSubtitle;
            this.fullogTitle = (exhibitionMainArtist ? exhibitionMainArtist + ' ' : "") + exhibitionTitle + (
                exhibitionSubtitle ? ' ' + exhibitionSubtitle : "");

            console.log(this.fullogTitle);
        },
        methods: {
            scrollTo(el) {
                let scrollTarget = el.target.dataset.scrolltarget;
                let elmnt = document.getElementById(scrollTarget);
                elmnt.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            },
            pushToPanel: function (passValue, getType) {
                this.$refs.panel.openPanel(passValue, getType);
            }
        }

    };
</script>
